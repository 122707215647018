
.w- {
    &100 {width: 100%;}
    &80 {width: 80%;}
    &70 {width: 70%;}
    &60 {width: 60%;}
    &50 {width: 50%;}
    &40 {width: 40%;}
    &30 {width: 30%;}
    &25 {width: 25%;}
    &auto {width: auto;}
}

.h- {
    &200 {height: 200px;}
    &150 {height: 150px;}
    &100 {height: 100px;}
    &50 {height: 50px;}
    &25 {height: 25px;}
    &10 {height: 10px;}
}

.p- {
    &5 {padding: 5px;} 
    &10 {padding: 10px;} 
    &15 {padding: 15px;} 
    &20 {padding: 20px;} 
}

.m- {
    &5 {padding: 5px;} 
    &10 {padding: 10px;} 
    &15 {padding: 15px;} 
    &20 {padding: 20px;} 
}

.d- {
    &block {display: block;}
    &flex {
        display: flex;
        &-center {
            flex-direction: initial;
            justify-content: center;
            column-gap: 15px;
        }
        &-autogap {
            display: flex;
            flex-direction: initial;
            justify-content: space-between!important;
            -webkit-box-pack: justify!important;
        }
    }
    &none {display: none;}
    
}

.gap {
    &-10 {
        display: flex;
        gap: 10px;
    }
}

.center {
    margin:0 auto;
}

@media  only screen and (max-width: 500px) and (min-width: 1px) {
    .w- {
        &100 {width: 100%;}
        &80 {width: 100%;}
        &70 {width: 100%;}
        &60 {width: 100%;}
        &50 {width: 100%;}
        &40 {width: 100%;}
        &30 {width: 100%;}
        &25 {width: 100%;}
    }

    .p- {
        &5 {padding: 5px;} 
        &10 {padding: 5px;} 
        &15 {padding: 5px;} 
        &20 {padding: 5px;} 
    }
    
    .m- {
        &5 {padding: 5px;} 
        &10 {padding: 5px;} 
        &15 {padding: 5px;} 
        &20 {padding: 5px;} 
    }

    .d- {
        &block {display: block;}
        &flex {
            display: block;
            &-center {
                flex-direction: initial;
                justify-content: center;
                column-gap: 15px;
            }
            &-autogap {
                display: flex;
                flex-direction: column;
                justify-content: space-between !important;
                -webkit-box-pack: justify !important;
                margin: 0 auto;
            }
        }

        
    }

    .gap {
        &-10 {
            display: flex;
            gap: 10px;
            justify-content: center !important;
            margin: 10px 0;
        }
    }
}