.footer {
    padding-top: 50px;
    background-color: #000;
    height: 100%;
    min-height: 600px;
    .footer-logo{
        margin: 0 auto;
        display: block;
        width: 80%;
        .logo {
        display: block;
        width: 80%;
        max-width: 100px;
        margin-bottom: 25px;
        }
    }    
    p {
        color:white;
        text-align: left;
        margin: 0;
    }
    .logo-maya {
        margin-top:25px;
    }
    .copywrite {
        border-top: 1px solid #4c4c4c99;
        color:white;

    }
    .flag {
        width: 1.5em;
    height: 1.5em;
    margin-right: 0.2em;
    border-radius: 50rem;
    }
}

.sitemap {
    text-align: left;
    span {
        color:white;
        text-align: left;
        font-weight: 500;
    }
    ul {
        color: rgba(255, 255, 255, 0.6);
        list-style: none;
        text-align: left;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        li a {
            color: rgba(255, 255, 255, 0.6);text-decoration: none;}
        li svg{padding-right:5px ;}

    }
}

.payments {
    height: 33px;;
    margin:0 auto;
}

@media  only screen and (max-width: 500px) and (min-width: 1px) {
    .footer {
        .footer-logo{
            width: 90%;
            .logo {
            width: 90%;
            max-width: 70px;
            }
        }
        p {
            font-size:13px;
        }
    }
    .sitemap {
        width: 90% !important;
        margin: 0 auto;
        display: block !important;
        div.w-25 {
            margin-top:25px ;
            font-size: 14px;
        }
        ul {
            color: rgba(255, 255, 255, 0.6);
            list-style: none;
            text-align: left;
            margin: 0;
            padding: 0;
            margin-top: 20px;
            li a {
                color: rgba(255, 255, 255, 0.6);
                text-decoration: none;
            }
            li svg {
                padding-right:5px;
            }
    
        }
    }
    .payments {
        height: 24px;
        margin:25px auto;
    }
    .copywrite {
        color: rgba(255, 255, 255, 0.6) !important;
        font-size: 14px;
    } 
}
